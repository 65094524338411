import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  // {
  //   path: "/admin",
  //   name: "Admin",
  //   component: () =>
  //     import(/* webpackChunkName: "Admin" */ "../views/Admin.vue"),
  // },
  // {
  //   path: '/careers',
  //   name: 'Careers',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/Careers.vue'),
  // },
  // {
  //   path: '/Contact',
  //   name: 'Contact',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
  // },
  // {
  //   path: '/Projects',
  //   name: 'Projects',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/Projects.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // Scroll to the top of the page when navigating to a new route
    return { top: 0 };
  },
});

export default router;
