<template>
  <div class="career-box">
    <!-- Bootstrap 5 card box -->
    <div class="card-box">
      <a class="fs-5 text">{{ career.position_name }} </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    career: Object,
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.career-box {
  justify-content: center;
  margin-top: 1px;
}

.card-box {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  margin-top: 15px;
  border-radius: 38px;
  background-color: #f07b22;
  cursor: pointer;
  padding: 16px 24px;
  min-height: 68px;
}

.text {
  white-space: nowrap;
}
.right-side {
  flex: 1;
  text-align: right;
}
@media (max-width: 1200px) {
  .text {
    white-space: normal;
  }
}
@media (max-width: 767.98px) {
  /* Small devices (landscape phones) */
  .align-center {
    justify-content: flex-start;
  }

  .text {
    white-space: normal;
  }
}

@media (max-width: 576px) {
  .text,
  .right-side > span {
    font-size: 16px !important ;
  }
  .card-box {
    width: 100%;
    margin: 6px auto;
  }
  .card-box > .left-side {
    width: 65% !important;
  }
  card-box > .right-side {
    width: 40% !important;
  }
}

.card-box .card-thumbnail {
  max-height: 200px;
  overflow: hidden;
  transition: 1s;
}

.card-box .card-thumbnail:hover {
  transform: scale(1.2);
}

.card-box h3 a {
  font-size: 10px;
  text-decoration: none;
  color: white;
}
</style>
