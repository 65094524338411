<template>
  <footer class="text-white bg-dark pb-3">
    <div class="col-12 col-md-12 line">
      <hr style="border-color: white; border-width: 2px" />
    </div>
    <div class="row container mx-auto g-0">
      <div
        class="col-md-12 col-xl-4 col-12 order-md-1 order-1 p-0 d-flex align-items-center justify-content-md-center justify-content-xl-start py-md-3 py-xl-0"
      >
        <div class="flex footer-links">
          <button @click="openModal('PrivacyPolicy')">Privacy Policy</button>

          <button @click="openModal('TermsOfService')">Terms of Service</button>

          <button @click="openModal('CookiesSetting')">Cookie Setting</button>
        </div>
      </div>

      <div
        class="col-12 col-md-12 col-xl-4 order-md-2 order-2 order-lg-2 order-xl-3 footer-tagline d-flex justify-content-center justify-content-md-center justify-content-xl-end py-2 p-md-0 align-items-center py-md-3 py-xl-0"
      >
        <div>
          © {{ currentYear }}
          <a
            class="cowlar-text"
            href="https://cowlardesignstudio.com/"
            target="_blank"
            >Cowlar Design Studio</a
          >
          . All right reserved.
        </div>
      </div>

      <div
        class="col-12 col-md-12 col-xl-4 order-md-3 order-lg-3 order-xl-2 order-3 d-flex justify-content-center align-items-center mx-lg-auto mx-lg-0"
      >
        <a class="navbar-brand" id="logo" href="#">
          <img
            alt="Vue logo"
            src="../assets/CowlarLogo.png"
            style="width: 200px"
          />
        </a>
      </div>
    </div>
  </footer>
  <!-- Footer -->

  <ModalWrapper
    :showModal="showModal"
    :currentModal="currentModal"
    @close-modal="closeModal"
  />
  <!-- End of .container -->
</template>

<script>
import ModalWrapper from "./ModalWrapper.vue";
export default {
  components: {
    ModalWrapper,
  },
  data() {
    return {
      showModal: false,
      currentModal: null,
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    openModal(modalName) {
      console.log(
        modalName + " called with and show modal is" + this.showModal,
      );
      this.currentModal = modalName;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
button {
  background: none;
  border: none;
  color: #ffffff;
  transition: 0.25s ease-in-out;
  opacity: 0.7;
}
.cowlar-text:hover,
button:hover {
  color: #f47726 !important ;
  opacity: 1;
  cursor: pointer;
}
.flex {
  display: flex;
  color: white;
  white-space: nowrap;
  justify-content: flex-start;
}

.flex a {
  text-decoration: underline;
  color: inherit;
}

#footerContainer {
  max-width: 100%;
  margin: 0px;
  margin-bottom: 0px;
  padding: 0px;
  margin-left: 0px;
}

.cowlar-text {
  transition: 0.25s ease-in-out;
  color: white;
}

.row2 {
  margin-top: 0px;
  margin-left: 100px;
  margin-right: 100px;
}

@media (max-width: 400px) {
  .footer-links {
    margin-left: -12px;
  }
}
@media (max-width: 768px) {
  .line {
    opacity: 0;
  }
  .text-md-start,
  .text-md-end,
  .text-center {
    text-align: center !important;
  }

  .row,
  .row2 {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .footer-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    flex-wrap: wrap;
    padding: 0 !important;
    margin: 0 auto !important ;
    margin-bottom: 16px !important;
    width: 100% !important;
  }
  .footer-links > a {
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
    text-decoration-line: underline;
    color: #ffffff;
  }
  .footer-tagline,
  .footer-tagline > a {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: #9da3bf;
  }
}
</style>
