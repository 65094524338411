<template>
  <div class="accordion">
    <div v-for="(item, index) in items" :key="index" class="accordion-item">
      <div class="accordion-header" @click="toggleAccordionItem(index)">
        <div>{{ item.title }}</div>
        <img src="../assets/down.svg" />
      </div>
      <div v-if="item.expanded" class="content-container">
        <div
          v-for="(job, index) in item.content"
          :key="index"
          class="accordion-content"
        >
          {{ job.Title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    docs: Object,
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    toggleAccordionItem(index) {
      this.items[index] = {
        ...this.items[index],
        expanded: !this.items[index].expanded,
      };
    },
  },
  mounted() {
    const docsData = Object.keys(this.docs).map((key) => ({
      title: key,
      content: this.data[key],
      expanded: false,
    }));
    this.items = docsData;
  },
};
</script>

<style scoped>
.accordion {
  max-width: 728px; /* Adjust the width as needed */
  background-color: black;
  padding: 16px !important;
  border-radius: 16px;
}

.accordion-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 8px;
  background-color: black;
  font-weight: 500;
  font-size: 24px;
}

.accordion-header {
  padding-block: 16px;
  margin-inline: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.content-container {
  background: rgba(255, 255, 255, 0.05);
  padding: 24px;
  border-radius: 24px;
}
.accordion-content {
  padding: 8px;
}
</style>
