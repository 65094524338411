<template>
  <!-- <router-link to="/admin"><div>Contact</div> </router-link> -->
  <router-view />
  <!-- <Home /> -->
</template>

<script>
export default {
  name: "App",
  components: {
    // Admin,
  },
};
</script>

<style>
.body {
  /* height: 520px; */
  margin-top: 50px;
  width: 100%;
  color: white;
  font-family: "Montserrat", sans-serif;
  background-color: #232323;
  /* margin-bottom: 40px; */
}

.bodyComponent {
  /* border: solid 1px; */
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 30px;
}
.sliderComponent {
  width: 100%;
  height: 50%;
}
.odd {
  background-color: rgb(28, 28, 28);
}
.even {
  background-color: #232323;
}
h2 {
  font-weight: 700 !important;
}
</style>
