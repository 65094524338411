<template>
  <div class="Header">
    <Header />
  </div>
  <div class="body">
    <div class="body">
      <section class="sliderComponent even" id="slider">
        <Slider />
      </section>
      <section class="bodyComponent odd" id="values">
        <OurTeams class="mt-0 my-0" />
      </section>
      <section class="bodyComponent odd" id="hiringProcess">
        <HiringProcess class="mt-0 my-0" />
      </section>

      <section class="bodyComponent even" id="careers">
        <Careers class="mt-0 my-0" />
      </section>
      <section class="bodyComponent even" id="prep-docs">
        <InterviewPreparation class="mt-0 my-0" />
      </section>

      <section class="bodyComponent even" id="topTalent">
        <TopTalent class="mt-0 my-0" />
      </section>

      <section class="bodyComponent even" id="contact">
        <Contact class="mt-0 my-0" />
      </section>
    </div>

    <div class="gotoTopBtn" v-show="gotoTopBtnDisplayState" @click="gotoTop">
      <svg
        width="54"
        height="50"
        viewBox="0 0 54 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <rect width="54" height="50" rx="10" fill="#BABBBF" />
        <rect x="9" y="7" width="36" height="36" fill="url(#pattern0)" />
        <defs>
          <pattern
            id="pattern0"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use xlink:href="#image0_1467_34" transform="scale(0.0111111)" />
          </pattern>
          <image
            id="image0_1467_34"
            width="90"
            height="90"
            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAAAXNSR0IArs4c6QAAA51JREFUeF7t3c9rE0EcBfDvlGB3ow1iafbSY0/9TzyI1pMHhaJHpYgn/wVPIvUoFPTgRfDoj7+lhx56GUopNJVUKomMNBDXJH3JS2Zn5QV6KPteZuezw2Z3C40zvaIIuCijaBCrI/SSmfUvf2pzCGsDvb6+nl9cXOya2cNL5A95nu8cHByc10G7FtAbGxvLp6enn51zt4dRnXNfsyzbqgN28tCbm5vXjo+PP5nZnTEr93ue53dTx04aGkAe2CePnSz0FMi1wE4Segbk5LGTgyaQk8ZOCnoOyMliJwM9R+QksZOAXgDyH2zn3Lcsy+6lcOlXOfS4m5Hha+Z+v/+lfLMy2D5pW8iE7c1m837V2JVCIyt5sCq73W531A3L6urq8hU3NEms7Mqgp0EOq7EoivAg6Z+X995N+15VPBupBHoWmEnQAW6W94wJHh16VpCroFPHjgqNfvC1Wq2t/f39n8MrDoEOeWaMRa7wqNBFUYTnyc/GTShcIYxCDnkUGsU2szfe++eLxP3rkW6sgcxsqSiKjpk1R405CXlaaBD7zHvfivWXmpgrOkD/MLOsDH0V8izQAPa59/66mfViLLaY0NZut/ecc9vlm5Fxp4tZztFltAnn7D3v/eMYyGGMqNBra2s3nHO7zrkHZvbLzN6trKy8LH/wjZr8NOfoUdidTueVmT0xs4aZfez1ejtHR0dn/yX00KTCAR55AzJu4gx0adzw61Rjz+NgRF3RzA7PCZrZBaoraIoPLwsat6KSgqb48LKgcSsqKWiKDy8LGreikoKm+PCyoHErKiloig8vCxq3opKCpvjwsqBxKyopaIoPLwsat6KSgqb48LKgcSsqKWiKDy8LGreikoKm+PCyoHErKiloig8vCxq3opKCpvjwsqBxKyopaIoPLwsat6KSgqb48LKgcSsqKWiKDy8LGreikoKm+PCyoHErKiloig8vCxq3opKCpvjwsqBxKyopaIoPLwsat6KSgqb48LKgcSsqKWiKDy8LGreikoKm+PCyoHErKiloig8vCxq3opKCpvjwsqBxKyopaIoPLwsat6KSgqb48LKgcSsqKWiKDy8LGreikoKm+PCyoHErKiloig8vCxq3opJ1gj4xs5ul2Z54729RApHKtYFut9tvnXNPSy673vudSFbUMLWBvvwq1Ndm9ijM2Dn3vtFovDg8PBz5/SyUygLKtYEemvtgn6P/a2LGv47QzHwr6wo6Ev1vAtFUeWuOAtkAAAAASUVORK5CYII="
          />
        </defs>
      </svg>
    </div>
  </div>

  <Footer />
</template>

<script>
import Slider from "@/components/Slider.vue";
import Vision from "@/components/Vision.vue";
import Projects from "@/components/Projects.vue";
import Team from "@/components/Team.vue";
import Careers from "@/components/Careers.vue";
import Values from "@/components/OurTeams.vue";
import Internship from "@/components/Internship.vue";
import Contact from "@/components/Contact.vue";
import PrivacyPolicy from "../components/PrivacyPolicy.vue";
import HiringProcess from "@/components/HiringProcess.vue";
import About from "@/components/About.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import OurTeams from "@/components/OurTeams.vue";
import TopTalent from "@/components/TopTalent.vue";
import InterviewPreparation from "@/components/InterviewPreparation.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    Slider,
    Vision,
    Projects,
    Team,
    HiringProcess,
    Internship,
    TopTalent,
    Contact,
    Careers,
    Values,
    About,
    OurTeams,
    PrivacyPolicy,
    InterviewPreparation,
  },
  data() {
    return {
      gotoTopBtnDisplayState: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      if (document.documentElement.scrollTop > 20) {
        this.gotoTopBtnDisplayState = true;
      } else {
        this.gotoTopBtnDisplayState = false;
      }
    },
    gotoTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
body {
  background-color: #232323 !important;
  margin-bottom: 0 !important;
  /* overflow-x: hidden; */
}
::-webkit-scrollbar {
  width: 8px;
  /* Adjust the width of the scrollbar */
  background-color: #f47726;
  color: #f47726;
}

::-webkit-scrollbar-thumb {
  background-color: #f47726 !important;
  /* Customize the thumb color */
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #343a40;
  /* Customize the track color */
}
</style>

<style scoped>
.gotoTopBtn {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  /* background-color: red; */
  /* color: white; */
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

.bodyComponent {
  padding-top: 128px;
}

.gotoTopBtn:hover {
  opacity: 0.6;
}

@media (max-width: 720px) {
  .bodyComponent {
    padding-top: 32px !important;
    padding-left: 20px !important;
    padding-right: 20px !important ;
    padding-bottom: 0 !important;
  }
}

@media (min-width: 1444px) {
  .bodyComponent {
    padding-top: 96px;
  }
}

@media (min-width: 1920px) {
  .bodyComponent {
    padding-top: 128px;
  }
}
</style>
