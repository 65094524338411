<template>
  <div class="container">
    <div class="col-12 position-relative">
      <h2 class="align-center vision-text">Vision</h2>
    </div>
    <div class="row justify-content-center">
      <div
        class="col-12 col-md-12 col-lg-12 row justify-content-center text-center padding-0"
      >
        <div class="col-12 text vision-description">
          Harnessing our multidisciplinary team's diverse expertise, we aim to
          revolutionize technology solutions with warp development speed and
          optimal quality. We strive to continuously innovate, creating holistic
          solutions across digital transformation, IoT, Robotics, and more,
          while also developing our own unique products. Our aspiration is to
          drive the future of technology through our Products, Platform as a
          Service and Software as a Service offerings, marking our footprint in
          every technological evolution.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServicesCard from "@/components/cards/ServicesCard.vue";

export default {
  // name: "AddTask",
  components: {
    ServicesCard,
  },
  data() {
    return {
      Services: Array,
    };
  },
  methods: {},
  mounted() {
    this.Services = [
      {
        id: 1,
        title: "Software Solution Architect",
      },
      {
        id: 2,
        title: "Senior Full Stack Developer",
      },
      {
        id: 3,
        title: "Junior Full Stack Developer",
      },
      {
        id: 4,
        title: "SQA",
      },
      {
        id: 5,
        title: "DevOps",
      },
      {
        id: 6,
        title: "Project Manager",
      },
    ];
  },
};
</script>

<style scoped>
.align-center {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.152132px;
  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.card-box {
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px #c5c5c5;
  margin-bottom: 30px;
  float: left;
  border-radius: 10px;
}

.card-box .card-thumbnail {
  max-height: 200px;
  overflow: hidden;
  border-radius: 10px;
  transition: 1s;
}

.card-box .card-thumbnail:hover {
  transform: scale(1.2);
}

.card-box h3 a {
  font-size: 20px;
  text-decoration: none;
}

.text {
  font-family: "Montserrat";
  margin-top: 0.7vh;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  line-height: 150%;
  letter-spacing: 1px;
  color: #cec9c9;
}

@media (max-width: 768px) {
  .padding-0 {
    padding: 0 !important;
  }
  .vision-text {
    font-weight: 700 !important;
    font-size: 24px !important;
  }
  .vision-description {
    font-size: 16px !important;
    line-height: 120%;
    text-align: center;
    padding: 0 !important;

    letter-spacing: 2px !important;
  }
}
</style>
