<template>
  <div
    class="card-text d-flex justify-content-center"
    style="margin-bottom: 30px"
  >
    <h2>The Team</h2>
  </div>

  <div :key="member.id" v-for="member in members">
    <TeamCard :member="member" />
  </div>
</template>

<script>
import TeamCard from "@/components/cards/TeamCard.vue";
export default {
  name: "Team",
  components: {
    // Header,
    TeamCard,
  },
  data() {
    return {
      members: Array,
      nums: Object,
      order: Boolean,
    };
  },
  created() {
    this.order = true;
    this.members = [
      {
        id: "1",
        title: "Khan",
        position: "Co-founder",
        img: "../assets/dummy.jpg",
        description:
          "Prior to founding Rayn, group advising and consulting with global clients such as AT&T, GSMA, Telenor, StarHub, Telkom Malaysia, Google, Facebook, Grab, Gojek & Lazada. He led Accenture’s Sales & Operations in CMT ASEAN, growing it 7x in 3 years. He has worked with some of the regions fasting growing unicorns – one merging with a US listed SPAC, the other leading to a $18B merger to form the largest technology group in South East Asia.Prior to Accenture, Mo was a product engineer at a large telecommunications company in the US specializing in new product enhancements for wide area network communication analyzers.Mo is an active tech angel investor and board member. When he is not working, he finds himself busy teaching his children how best to use technology by conducting various robotics related projects around his household. Mo holds a BSc. in Electrical Engineering from Case Western Reserve University & has completed his Executive Education in Strategy & Innovation from the MIT Sloan School of Management. He considers himself a lifelong builder & learner!",
      },
      // {
      //   id: "2",
      //   title: "Nawaz",
      //   position: "Co-founder",
      //   img: "../assets/dummy.jpg",
      //   description:
      //     "Prior to founding Rayn, group advising and consulting with global clients such as AT&T, GSMA, Telenor, StarHub, Telkom Malaysia, Google, Facebook, Grab, Gojek & Lazada. He led Accenture’s Sales & Operations in CMT ASEAN, growing it 7x in 3 years. He has worked with some of the regions fasting growing unicorns – one merging with a US listed SPAC, the other leading to a $18B merger to form the largest technology group in South East Asia.Prior to Accenture, Mo was a product engineer at a large telecommunications company in the US specializing in new product enhancements for wide area network communication analyzers.Mo is an active tech angel investor and board member. When he is not working, he finds himself busy teaching his children how best to use technology by conducting various robotics related projects around his household. Mo holds a BSc. in Electrical Engineering from Case Western Reserve University & has completed his Executive Education in Strategy & Innovation from the MIT Sloan School of Management. He considers himself a lifelong builder & learner!",
      // },
      // {
      //   id: "3",
      //   title: "Nawaz",
      //   position: "Co-founder",
      //   img: "../assets/dummy.jpg",
      //   description:
      //     "Prior to founding Rayn, group advising and consulting with global clients such as AT&T, GSMA, Telenor, StarHub, Telkom Malaysia, Google, Facebook, Grab, Gojek & Lazada. He led Accenture’s Sales & Operations in CMT ASEAN, growing it 7x in 3 years. He has worked with some of the regions fasting growing unicorns – one merging with a US listed SPAC, the other leading to a $18B merger to form the largest technology group in South East Asia.Prior to Accenture, Mo was a product engineer at a large telecommunications company in the US specializing in new product enhancements for wide area network communication analyzers.Mo is an active tech angel investor and board member. When he is not working, he finds himself busy teaching his children how best to use technology by conducting various robotics related projects around his household. Mo holds a BSc. in Electrical Engineering from Case Western Reserve University & has completed his Executive Education in Strategy & Innovation from the MIT Sloan School of Management. He considers himself a lifelong builder & learner!",
      // },
    ];
  },
};
</script>

<style scoped></style>
