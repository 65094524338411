<template>
  <section class="bodyComponent pb-0">
    <Modal :showModal="showModal" class="flex-column | custom-margin">
      <div class="top-section">
        <button
          type="button"
          class="close-btn"
          aria-label="Close"
          @click="closeModal"
        >
          <span class="close"></span>
        </button>
      </div>
      <component :is="currentModal" class="custom" />
    </Modal>
  </section>

  <Footer />
</template>

<script>
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import CookiesSetting from "@/components/CookiesSetting.vue";
import TermsOfService from "@/components/TermsOfService.vue";
import Modal from "@/components/Modal/Modal.vue";
export default {
  name: "ModalWrapper",
  components: {
    PrivacyPolicy,
    CookiesSetting,
    TermsOfService,
    Modal,
  },
  props: {
    showModal: {
      default: false,
    },
    currentModal: {
      default: null,
    },
  },
  methods: {
    closeModal() {
      // Emit a custom event to inform the parent component
      this.$emit("close-modal");
    },
  },
};
</script>

<style scoped>
.top-section {
  display: flex;
  justify-content: end;
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.top-section,
.custom {
  padding-inline: 24px;
  padding-block: 16px;
  width: 80%;
  max-width: 1380px;
  background: black !important;
}
.custom {
  overflow: auto;
  border-radius: 8px;
  height: 80svh;
}

.bodyComponent {
  padding-top: 0;
}
.close-btn {
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  background: none !important;
  position: relative;
}
.close {
  position: absolute;
  right: 12px;
  top: 0px;
  width: 16px;
  height: 16px;
  opacity: 0.5;
  transition: 250ms ease-in-out;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 20px;
  width: 2px;
  background-color: #fff;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

@media (max-width: 720px) {
  .bodyComponent {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media (min-width: 1444px) {
  .bodyComponent {
    padding-top: 0px;
  }
}

@media (min-width: 1920px) {
  .bodyComponent {
    padding-top: 0;
  }
}
</style>
