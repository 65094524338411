<template>
  <section class="">
    <h2 class="title">TERMS AND CONDITIONS</h2>
    <p class="update-time">Last Update: 31st May 2023</p>
    <p class="tagline">
      PLEASE READ THIS TERMS AND CONDITIONS CAREFULLY. YOUR USE OF THE WEBSITE
      SIGNIFIES YOUR AGREEMENT TO BE BOUND BY THESE TERMS.
    </p>
    <div>
      <p class="section-description">
        These Terms and Conditions ('Terms') govern the use of the
        <a
          href="http://careers.cowlardesignstudio.com"
          target="_blank"
          rel="noopener noreferrer"
          >careers.cowlardesignstudio.com</a
        >
        website (the "Site") operated by Cowlar Design Studio ("Cowlar Design
        Studio," "we," "us," or "our"). By accessing or using the Site, you
        ("User" or "you") agree to be bound by these Terms. If you do not agree
        with these Terms, you must not use the Site.
      </p>
      <h3 class="section-heading">PRIVACY</h3>
      <p class="section-description">
        We respect your privacy and have developed a Privacy Policy that
        outlines how we collect, use, and protect your personal information. By
        using the Site, you acknowledge and agree to the terms of our Privacy
        Policy, which is incorporated into these Terms by reference.
      </p>

      <h3 class="section-heading">SITE ACCESS AND USE</h3>
      <div class="section-description">
        <ol>
          <li>
            Eligibility: The Site is intended for individuals who are at least
            18 years old. By accessing or using the Site, you represent and
            warrant that you are at least 18 years old. If you are accessing or
            using the Site on behalf of an organization, you represent and
            warrant that you have the authority to bind that organization to
            these Terms
          </li>
          <li>
            Account Registration: Some features of the Site may require you to
            create an account. You are responsible for providing accurate and
            complete information during the registration process. You must
            safeguard your account login credentials and notify us immediately
            of any unauthorized use or breach of security.
          </li>
          <li>
            Use of the Site: You agree to use the Site in compliance with these
            Terms, applicable laws, and any additional guidelines or policies
            provided by Cowlar Design Studio. You may not use the Site for any
            unlawful or unauthorized purpose or in a manner that infringes upon
            the rights of others.
          </li>
          <li>
            Intellectual Property: All content, trademarks, logos, and
            intellectual property on the Site are the property of Cowlar Design
            Studio or its licensors and are protected by copyright and other
            intellectual property laws. You may not reproduce, modify,
            distribute, or display any content from the Site without our prior
            written consent.
          </li>
          <li>
            Prohibited Activities You agree not to engage in any of the
            following activities:
            <ul>
              <li>
                Attempt to gain unauthorized access to the Site or its systems.
              </li>
              <li>
                Interfere with or disrupt the Site or its associated networks.
              </li>
              <li>
                Use the Site to transmit any harmful or malicious code or engage
                in any activity that could damage or impair the Site's
                functionality.
              </li>
              <li>
                Post or submit any false, misleading, or defamatory information.
              </li>
              <li>
                Violate any applicable laws or regulations in connection with
                your use of the Site.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <h3 class="section-heading">USER DATA AND APPLICATIONS</h3>
      <div class="section-description">
        <ol>
          <li>
            Submission of Information: When you apply for a position through the
            Site, you may be required to provide personal information, such as
            your name, address, contact number, CV, and other relevant details.
            By submitting this information, you consent to its collection, use,
            and storage in accordance with our Privacy Policy.
          </li>
          <li>
            Accuracy of Information: You are responsible for ensuring the
            accuracy and completeness of the information provided in your
            application. Cowlar Design Studio reserves the right to verify the
            information provided and may reject or remove any application that
            contains false or misleading information.
          </li>
          <li>
            Data Protection: We take reasonable measures to protect the security
            of your personal information; however, no method of transmission
            over the internet or electronic storage is completely secure. You
            acknowledge and accept the inherent risks associated with providing
            personal information online.
          </li>
          <li>
            Use of Application Materials: The information and materials
            submitted in your application, including your CV, will be used
            solely for the purpose of evaluating your suitability for employment
            opportunities at Cowlar Design Studio. We may share this information
            with our authorized agents or partners involved in the recruitment
            process.
          </li>
          <li>
            Retention of Data: We will retain your application data for a
            reasonable period or as required by applicable laws and regulations.
            If your application is successful and you are offered a position at
            Cowlar Design Studio, your application data may be retained as part
            of your employment record.
          </li>
        </ol>
      </div>

      <h3 class="section-heading">DISCLAIMER OF WARRANTIES</h3>
      <div class="section-description">
        <ol>
          <li>
            The Site is provided on an "as is" and "as available" basis, without
            warranties of any kind, whether express or implied. Cowlar Design
            Studio disclaims all warranties,including but not limited to, the
            implied warranties of merchantability, fitness for a particular
            purpose, and non-infringement.
          </li>
          <li>
            Cowlar Design Studio does not guarantee the accuracy, completeness,
            or reliability of any content on the Site. You acknowledge and agree
            that your use of the Site is at your own risk.
          </li>
        </ol>
      </div>

      <h3 class="section-heading">LIMITATION OF LIABILITY</h3>
      <div class="section-description">
        <ol>
          <li>
            In no event shall Cowlar Design Studio be liable for any direct,
            indirect, incidental, special, consequential, or punitive damages
            arising out of or in connection with your use of the Site. This
            includes, but is not limited to, damages for loss of profits,
            goodwill, data, or other intangible losses.
          </li>
          <li>
            Cowlar Design Studio shall not be liable for any errors, omissions,
            interruptions, or delays in the operation of the Site or any content
            or services provided on or through the Site.
          </li>
          <li>
            To the maximum extent permitted by applicable law, the total
            liability of Cowlar Design Studio for any claims arising out of or
            relating to the Site shall not exceed the amount you paid, if any,
            to access or use the Site
          </li>
        </ol>
      </div>

      <h3 class="section-heading">INDEMNIFICATION</h3>
      <p class="section-description">
        You agree to indemnify, defend, and hold harmless Cowlar Design Studio,
        its affiliates, directors, officers, employees, and agents from and
        against any claims, liabilities, damages, losses, costs, or expenses
        arising out of or in connection with your use of the Site or any
        violation of these Terms.
      </p>

      <h3 class="section-heading">TERMINATION</h3>
      <p class="section-description">
        Cowlar Design Studio reserves the right, in its sole discretion, to
        terminate or suspend your access to the Site at any time and for any
        reason, without prior notice or liability.
      </p>

      <h3 class="section-heading">GOVERNING LAW AND JURISDICTION</h3>
      <p class="section-description">
        These Terms shall be governed by and construed in accordance with the
        laws of the jurisdiction where Cowlar Design Studio is located. Any
        dispute arising out of or relating to these Terms or the use of the Site
        shall be subject to the exclusive jurisdiction of the courts in that
        jurisdiction.
      </p>

      <h3 class="section-heading">SEVERABILITY</h3>
      <p class="section-description">
        If any provision of these Terms is found to be invalid, illegal, or
        unenforceable, the remaining provisions shall continue in full force and
        effect.
      </p>

      <h3 class="section-heading">CHANGES TO THE TERMS</h3>
      <p class="section-description">
        Cowlar Design Studio reserves the right to update or modify these Terms
        at any time without prior notice. Any changes to the Terms will be
        effective immediately upon posting on the Site. Your continued use of
        the Site after the posting of any changes constitutes your acceptance of
        the modified Terms.
      </p>

      <h3 class="section-heading">CONTACT INFORMATION</h3>
      <p class="section-description">
        If you have any questions or concerns about these Terms or the Site,
        please contact us at
        <a href="mailto:info@cowlar.com">info@cowlar.com</a>. By using the Site,
        you acknowledge that you have read, understood, and agree to be bound by
        these Terms and any additional terms and policies referenced herein.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "TermsOfService",
};
</script>

<style scoped>
.container {
  margin: auto !important;
}

.title {
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.update-time {
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.tagline {
  font-weight: 600;
  font-size: 32px;
  line-height: 107.5%;
  letter-spacing: -0.148861px;
  color: #f07b22;
}

.section-heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 107.5%;
  letter-spacing: -0.148861px;
  color: #ffffff;
  margin: 48px 0px 24px 0px;
}

.section-description,
li {
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.148861px;
  color: #b5b8ba;
}

li {
  padding-bottom: 16px;
}

.section-description > a {
  color: #ed5023;
  text-decoration: none;
}

li {
  padding-bottom: 16px;
}

@media (max-width: 768px) {
  .container {
    padding: 8px 20px;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 107.5%;
    /* identical to box height, or 26px */

    letter-spacing: -0.148861px;
    text-transform: uppercase;

    color: #ffffff;
  }

  .update-time {
    font-weight: 300;
    font-size: 14px;
    line-height: 107.5%;
    /* identical to box height, or 15px */

    letter-spacing: -0.148861px;

    color: #ffffff;
  }

  .tagline {
    font-weight: 600;
    font-size: 20px;
    line-height: 107.5%;
    /* or 22px */

    letter-spacing: -0.148861px;

    color: #f07b22;
  }

  .section-heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 107.5%;
    /* or 19px */

    letter-spacing: -0.148861px;

    color: #ffffff;
  }

  .section-description,
  li {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: -0.148861px;

    color: #b5b8ba;
  }
}
</style>
