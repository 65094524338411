import axios from "axios";
import config from "../utils/config/index";

const api = axios.create({
  baseURL: config.url,
});

export const getRequest = async (url) => {
  return await api.get(url);
};

export const postRequest = async (url, data) => {
  return await api.post(url, data);
};
