<template>
  <header class="navbar-dark bg-dark fixed-top py-2 px-md-5">
    <!-- Navbar -->
    <nav class="navbar navbar-expand-xl" id="nav">
      <div class="" id="logo">
        <img alt="Vue logo" src="../assets/CowlarLogo.png" />
      </div>

      <button
        class="navbar-toggler"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        id="myButton"
      >
        <span class="navbar-icon">
          <svg
            width="26"
            height="26"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.33333 9.33366C2.06971 9.33366 2.66667 8.73671 2.66667 8.00033C2.66667 7.26395 2.06971 6.66699 1.33333 6.66699C0.596954 6.66699 0 7.26395 0 8.00033C0 8.73671 0.596954 9.33366 1.33333 9.33366Z"
              fill="#F47726"
            />
            <path
              d="M22.7493 6.66699H6.58927C5.89707 6.66699 5.33594 7.22813 5.33594 7.92033V8.08033C5.33594 8.77252 5.89707 9.33366 6.58927 9.33366H22.7493C23.4415 9.33366 24.0026 8.77252 24.0026 8.08033V7.92033C24.0026 7.22813 23.4415 6.66699 22.7493 6.66699Z"
              fill="#F47726"
            />
            <path
              d="M22.7467 13.333H1.25333C0.561136 13.333 0 13.8941 0 14.5863V14.7463C0 15.4385 0.561136 15.9997 1.25333 15.9997H22.7467C23.4389 15.9997 24 15.4385 24 14.7463V14.5863C24 13.8941 23.4389 13.333 22.7467 13.333Z"
              fill="#F47726"
            />
            <path
              d="M22.7467 0H1.25333C0.561136 0 0 0.561136 0 1.25333V1.41333C0 2.10553 0.561136 2.66667 1.25333 2.66667H22.7467C23.4389 2.66667 24 2.10553 24 1.41333V1.25333C24 0.561136 23.4389 0 22.7467 0Z"
              fill="#F47726"
            />
          </svg>
        </span>
        <span class="navbar-toggler-icon-close"></span>
      </button>

      <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarNavAltMarkup"
      >
        <div class="navbar-nav text-montserrat">
          <a
            class="nav-link active"
            href="http://cowlardesignstudio.com"
            target="_blank"
            >Home</a
          >

          <span class="vl"></span>
          <a
            class="nav-link active"
            @click="
              scrollToSection('#values');
              closeMobileMenu();
            "
            >Teams</a
          >

          <span class="vl"></span>

          <a
            class="nav-link active"
            @click="
              scrollToSection('#hiringProcess');
              closeMobileMenu();
            "
            >Hiring Process</a
          >

          <span class="vl"></span>

          <a
            class="nav-link active"
            @click="
              scrollToSection('#careers');
              closeMobileMenu();
            "
            >Open Positions</a
          >

          <span class="vl"></span>

          <a
            class="nav-link active"
            @click="
              scrollToSection('#prep-docs');
              closeMobileMenu();
            "
            >Interview Prep Docs</a
          >

          <span class="vl"></span>
          <a
            class="nav-link active last"
            @click="
              scrollToSection('#contact');
              closeMobileMenu();
            "
            >Contact Us</a
          >

          <div class="mobile-menu-footer">
            2023 Cowlar Design Studio. All right reserved.
          </div>
        </div>

        <!-- Mobile Menu Footer -->
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isMobileMenuOpen: false,
    };
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.querySelector(sectionId);
      if (section) {
        const offset = section.offsetTop - 50;
        window.scrollTo({ top: offset, behavior: "smooth" });
      }
    },
    closeMobileMenu() {
      const navbarToggle = document.getElementById("myButton");
      const navbarCollapse = document.getElementById("navbarNavAltMarkup");

      // Check if the mobile menu is open
      if (navbarToggle.getAttribute("aria-expanded") === "true") {
        // Close the mobile menu , This will show the close icon but not hide the menu
        navbarToggle.setAttribute("aria-expanded", "false");
        //this will actually hide the mobile menu
        navbarCollapse.classList.remove("show");
      }
    },
  },
};
</script>

<style scoped>
/* Common Classes */
.col-5 {
  justify-content: center;
}

/* Common Classes End Here */

#nav {
  padding: 0;
  max-width: 1800px;
  margin-inline: auto;
}

.branding-margin-left {
  margin-left: 100px !important;
}

.navbar-nav {
  font-size: 18px;
}

.vl {
  border-left: 2px solid rgb(255, 255, 255);
  height: 25px;
  margin-top: 8px;
  margin-left: 15px;
  margin-right: 15px;
  opacity: 0.3;
  border-radius: 15px;
  font-family: "Montserrat" !important;
}

#logo > img {
  width: 160px;
}

.nav-link:hover {
  color: #f47726 !important ;
  opacity: 1;
  cursor: pointer;
}

/* Mobile Footer Styling */
.mobile-menu-footer {
  color: #9da3bf;
  font-size: 14px;
  display: none;
}

#myButton:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler {
  transition: 0.5s ease-in-out;
}
.navbar-icon {
  transition: 0.25s ease-in-out;
}

@media (max-width: 576px) {
  #nav {
    padding: 10px 24px !important;
  }

  #logo > img {
    width: 96px !important;
  }
}

@media (max-width: 1198px) {
  .vl {
    display: none;
  }

  .branding-margin-left {
    margin-left: 0 !important;
  }

  .nav-link {
    margin-block: 4px;
    font-weight: 600;
    font-size: 24px;
    transition: 0.25s ease-in-out;
  }

  .navbar-nav {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 95vh !important;
    align-items: center;
    justify-content: center;
  }

  /* Hide default navbar toggler icon when the collapse is open */
  .navbar-toggler[aria-expanded="true"] .navbar-icon {
    display: none;
  }

  /* Show close icon when the collapse is open */
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon-close {
    display: inline-block;
  }

  .navbar-toggler-icon-close {
    width: 24px;
    height: 24px;
    background-image: url("../assets/close-icon.svg");
    /* Replace with your close icon image path */
    background-repeat: no-repeat;
    background-size: cover;
  }

  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
    border: none !important;
    padding: 0;
    outline: none !important;
  }

  /* Footer */
  .mobile-menu-footer {
    position: absolute;
    display: block;
    bottom: 4%;
    margin: 0 auto;
  }

  /* Logo Size Small on Mobile Screens */
}

/* ------ */
</style>
