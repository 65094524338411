<template>
  <div>
    <!-- Bootstrap 5 card box -->
    <div class="card-box">
      <h3>
        <a class="mt-2 text-danger">{{ Service.title }} </a>
      </h3>
      <p class="text-secondary">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim facere
        atque ab voluptatem exercitationem amet?
      </p>
      <!-- <a  class="btn btn-sm btn-danger float-right">Read more >></a> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    Service: Object,
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.card-hover:hover {
  transform: translateY(-2px);
  cursor: pointer;
  /* opacity: 1.5; */
}

.card-box {
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px #c5c5c5;
  margin-bottom: 30px;
  float: left;
  border-radius: 10px;
}
.card-box .card-thumbnail {
  max-height: 200px;
  overflow: hidden;
  border-radius: 10px;
  transition: 1s;
}
.card-box .card-thumbnail:hover {
  transform: scale(1.2);
}
.card-box h3 a {
  font-size: 20px;
  text-decoration: none;
}
</style>
