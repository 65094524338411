<template>
  <div>
    <div class="container">
      <div class="row career-container">
        <div class="col-12">
          <h2 class="mt-0 align-center | custom-heading">
            Interview Preparation Documents
          </h2>
        </div>

        <!-- Search Bar -->

        <div class="accordion">
          <div class="search-container">
            <img src="../assets/search.svg" class="search-icon" />
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Search"
              class="search-bar"
            />
          </div>
          <div
            v-if="filteredSections.length < 1"
            :key="index"
            class="mt-4 text-center p-2"
          >
            No Preparation Document available
          </div>
          <div v-else>
            <div
              v-for="(item, index) in filteredSections"
              :key="index"
              class="accordion-item"
            >
              <div class="accordion-header" @click="toggleAccordionItem(index)">
                <div>
                  {{ item.title }} <span>({{ item.content.length }})</span>
                </div>
                <img
                  :style="{
                    transform: item.expanded
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)',
                  }"
                  src="../assets/down.svg"
                />
              </div>
              <div v-if="item.expanded" class="content-container">
                <a
                  :href="job.preparationDoc"
                  target="_blank"
                  v-for="(job, index) in item.content"
                  :key="index"
                  class="accordion-content"
                >
                  <img src="../assets/docs.svg" alt="" />
                  <span>{{ job.Title }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreparationCard from "@/components/cards/PreparationCard.vue";
import config from "../utils/config";
import jsonData from "../assets/jd's/jd.json";
import Accordion from "./Accordion.vue";

export default {
  name: "InterviewPreparation",
  components: {
    PreparationCard,
    Accordion,
  },
  data() {
    return {
      config: config,
      jobsSection: [],
      filteredSections: [],
      searchQuery: "",
    };
  },
  watch: {
    searchQuery(newValue, oldValue) {
      // Check if the search query has changed before filtering
      if (newValue !== oldValue) {
        this.filteredSections = this.jobsSection
          .map((section) => ({
            ...section,
            expanded: section.content.some((job) =>
              job.Title.toLowerCase().includes(newValue.toLowerCase()),
            ),
            content: section.content.filter((job) =>
              job.Title.toLowerCase().includes(newValue.toLowerCase()),
            ),
          }))
          .filter((section) => section.content.length > 0);
      }
    },
  },
  mounted() {
    this.jobsSection = this.splitJobsSection(jsonData);
    this.jobsSection = this.departmentsWithPreparationDocs(this.jobsSection);
    this.jobsSection = Object.keys(this.jobsSection).map((key) => ({
      title: key,
      content: this.jobsSection[key],
      expanded: false,
    }));
    this.filteredSections = this.jobsSection;
  },
  methods: {
    splitJobsSection(data) {
      const grouped = {};
      for (const job of data) {
        if (!grouped[job.section]) {
          grouped[job.section] = [];
        }
        grouped[job.section].push(job);
      }
      return grouped;
    },
    departmentsWithPreparationDocs(jobsSection) {
      const filteredDepartments = {};
      for (const departmentName in jobsSection) {
        if (departmentName !== "Internship") {
          const departmentJobs = this.jobsSection[departmentName];
          const jobsWithPreparationDocs = departmentJobs.filter(
            (job) => job.preparationDoc,
          );

          if (jobsWithPreparationDocs.length > 0) {
            filteredDepartments[departmentName] = jobsWithPreparationDocs;
          }
        }
      }
      return filteredDepartments;
    },
    toggleAccordionItem(index) {
      this.filteredSections[index] = {
        ...this.filteredSections[index],
        expanded: !this.filteredSections[index].expanded,
      };
    },
  },
};
</script>

<style scoped>
* {
  transition: 250ms ease-in-out;
}
a {
  color: #ffffff;
  text-decoration: none;
}
a:hover {
  color: #ffffff;
}
.search-container {
  position: relative;
}
.search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  height: 35px;
  opacity: 0.6;
}
.search-bar {
  padding: 16px 50px;
  border-radius: 8px;
  outline: none;
  color: #fff;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.05);
  max-width: 100%;
}

.accordion {
  max-width: 880px; /* Adjust the width as needed */
  background-color: rgba(32, 32, 32, 1);
  padding: 16px !important;
  border-radius: 16px;
  margin-top: 16px;
}

.accordion-item {
  margin-bottom: 8px;
  background-color: rgba(32, 32, 32, 1);
  font-weight: 500;
  font-size: 24px;

  border: none;
}

.accordion-item:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.accordion-header {
  padding-block: 16px;
  margin-inline: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.content-container {
  background: rgba(255, 255, 255, 0.05);
  padding: 24px;
  border-radius: 24px;
  margin-bottom: 16px;
}
.accordion-content {
  margin-block: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  font-size: 16px;
}
.accordion-content:hover {
  background: #f07b22;
  cursor: pointer;
}
</style>

<style scoped>
.container {
  overflow: hidden;
}

.row {
  justify-content: center;
}

.card-box2 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 25px 0px 0px 0px;
}

.align-center {
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.144962px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.card-hover:hover {
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .career-container {
    padding: 0 !important;
  }
  .career-container div > h2 {
    padding: 0 !important;
    margin: 0 !important;
  }
  .card-box2 > h4 {
    font-weight: 500 !important;
    font-size: 18px !important;
  }
}

.career-link {
  text-decoration: none;
  color: inherit;
}
</style>
