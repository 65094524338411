<template>
  <section class="">
    <h2 class="title">PRIVACY POLICY</h2>
    <p class="update-time">Last Update: 31st May 2023</p>
    <p class="tagline">
      PLEASE READ THIS PRIVACY POLICY CAREFULLY. YOUR USE OF THE WEBSITE
      SIGNIFIES YOUR AGREEMENT TO BE BOUND BY THESE TERMS.
    </p>
    <div>
      <h3 class="section-heading">PRIVACY & SECURITY</h3>
      <p class="section-description">
        At Cowlar Design Studio, we value and respect your privacy. This Privacy
        Policy explains how we collect, use, and protect the personal
        information you provide when applying for a career opportunity on the
        <a
          href="http://careers.cowlardesignstudio.com"
          target="_blank"
          rel="noopener noreferrer"
          >careers.cowlardesignstudio.com</a
        >
        website. By accessing or using the Website, you consent to the practices
        described in this Privacy Policy.
      </p>

      <h3 class="section-heading">COLLECTION OF PERSONAL INFORMATION & USE</h3>
      <p class="section-description">
        When you apply for a career opportunity on the Website, we collect
        personal information that you voluntarily provide to us. This
        information may include your name, address, phone number, email address,
        CV or resume, and any other information you choose to submit. We use
        this information solely for the purpose of evaluating your application
        and assessing your suitability for the position you applied for We may
        also use your contact information to communicate with you regarding your
        application, provide updates on the hiring process, or request
        additional information if needed. We may retain your information for
        future employment opportunities, unless you request us to delete it.
      </p>

      <h3 class="section-heading">SECURITY OF YOUR INFORMATION</h3>
      <p class="section-description">
        We take reasonable measures to protect the personal information
        submitted through the Website. We implement appropriate technical,
        administrative, and physical safeguards to prevent unauthorized access,
        disclosure, alteration, or destruction of your information. However,
        please note that no method of transmission or storage over the internet
        is completely secure, and we cannot guarantee the absolute security of
        your data.
      </p>

      <h3 class="section-heading">ACCESS TO INFORMATION</h3>
      <p class="section-description">
        If you have any questions about the information we hold about you or if
        you wish to update, correct, or delete your personal information, please
        contact us using the contact details provided below. We will make
        reasonable efforts to respond to your request in a timely manner.
      </p>

      <h3 class="section-heading">LINKS TO OTHER SITES</h3>
      <p class="section-description">
        The Website may contain links to third-party websites that are not
        operated or controlled by Cowlar Design Studio. This Privacy Policy
        applies only to our Website, and we are not responsible for the privacy
        practices or content of any third-party websites. We encourage you to
        review the privacy policies of those websites before providing any
        personal information.
      </p>

      <h3 class="section-heading">CHANGES TO POLICY</h3>
      <p class="section-description last">
        We reserve the right to modify or update this Privacy Policy at any time
        without prior notice. Any changes will be effective immediately upon
        posting of the revised Privacy Policy on the Website. Your continued use
        of the Website after the posting of any changes signifies your
        acceptance of the revised Privacy Policy.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.title {
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.update-time {
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.tagline {
  font-weight: 600;
  font-size: 32px;
  line-height: 107.5%;
  letter-spacing: -0.148861px;
  color: #f07b22;
}

.section-heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 107.5%;
  letter-spacing: -0.148861px;
  color: #ffffff;
  margin: 48px 0px 24px 0px;
}

.section-description > a {
  color: #ed5023;
  text-decoration: none;
}

.section-description,
li {
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.148861px;
  color: #b5b8ba;
}
li {
  padding-bottom: 16px;
}
@media (max-width: 768px) {
  .last {
    margin-bottom: 0 !important;
  }
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 107.5%;
    /* identical to box height, or 26px */

    letter-spacing: -0.148861px;
    text-transform: uppercase;

    color: #ffffff;
  }

  .update-time {
    font-weight: 300;
    font-size: 14px;
    line-height: 107.5%;
    /* identical to box height, or 15px */

    letter-spacing: -0.148861px;

    color: #ffffff;
  }

  .tagline {
    font-weight: 600;
    font-size: 20px;
    line-height: 107.5%;
    /* or 22px */

    letter-spacing: -0.148861px;

    color: #f07b22;
  }

  .section-heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 107.5%;
    /* or 19px */

    letter-spacing: -0.148861px;

    color: #ffffff;
  }

  .section-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: -0.148861px;

    color: #b5b8ba;
  }
}
</style>
