<template>
  <div
    id="carouselExampleDark"
    class="carousel carousel-dark slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          src="../assets/hero-bg.png"
          class="d-block w-100 hero-img"
          alt=""
        />
        <div class="slider-text my-5 mt-lg-0">
          <div class="paragraph-container">
            <p class="mb-4 mb-md-5">
              Product design, engineering, development and manufacturing
            </p>
          </div>
          <div class="text-3xl text-4xl mb-3 mb-md-4 h1 mx-4 mx-sm-0">
            <span class="text-orange">JOIN US </span>
            <span class="text-white"
              >to build disruptive tech at warp speed!</span
            >
          </div>
          <div class="d-inline-flex gap-lg-4 flex-column flex-lg-row my-4">
            <a
              href="https://cowlardesignstudio.com/"
              class="btn btn-secondary bg-transparent d-inline-flex"
              target="_blank"
              ><span>About Our Company</span>
            </a>
            <a
              href="#careers"
              class="btn btn-primary d-inline-flex gap-lg-2 mx-auto"
              ><span>Apply Now</span>
              <span class="vector-icon">
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.00445 0.999594L7.00445 16.5779L1.71407 11.2875C1.62163 11.193 1.51137 11.1178 1.38966 11.0662C1.26795 11.0146 1.13721 10.9876 1.00501 10.9869C0.872814 10.9862 0.741788 11.0117 0.619516 11.0619C0.497244 11.1122 0.386158 11.1862 0.292687 11.2797C0.199218 11.3732 0.125221 11.4843 0.0749836 11.6065C0.0247469 11.7288 -0.000736237 11.8599 1.52588e-05 11.9921C0.000766754 12.1242 0.0277367 12.255 0.079361 12.3767C0.130985 12.4984 0.206239 12.6086 0.300765 12.7011L7.29731 19.6976C7.48474 19.885 7.73892 19.9902 8.00395 19.9902C8.26899 19.9902 8.52317 19.885 8.71061 19.6976L15.7071 12.7011C15.8001 12.6083 15.8739 12.4982 15.9241 12.3768C15.9744 12.2555 16.0002 12.1255 16 11.9942C16 11.7965 15.9413 11.6033 15.8315 11.439C15.7217 11.2746 15.5656 11.1466 15.383 11.0709C15.2003 10.9953 14.9994 10.9755 14.8056 11.0141C14.6117 11.0526 14.4336 11.1478 14.2938 11.2875L9.00346 16.5779L9.00346 0.999594C9.00346 0.734509 8.89816 0.48028 8.71071 0.292835C8.52327 0.105392 8.26904 8.7738e-05 8.00395 8.7738e-05C7.73887 8.7738e-05 7.48464 0.105392 7.2972 0.292835C7.10975 0.48028 7.00445 0.734509 7.00445 0.999594Z"
                    fill="white"
                  />
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.carousel-item {
  position: relative;
}
.arrow {
  width: 1.1vw;
  height: 2vh;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.vl {
  position: absolute;
  border-left: 3px solid rgb(255, 255, 255);
  height: 12px;
  right: 8px;
  top: 7px;
  /* margin-top: 8px;
  margin-left: 15px;
  margin-right: 15px; */
  opacity: 0.8;
  border-radius: 15px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin: 0px 0px 3px 10px;
}

.btn-apply-now {
  color: #f47726 !important;
  background-color: #f47726 !important;
}

.tales {
  width: 100%;
  background-color: black;
}

.template {
  background-color: black !important;
}

.carousel-inner {
  width: 100%;
  max-height: 600px !important;
}

.slider-text {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 6;
  font-weight: 500;
}

.carousel-caption {
  z-index: 5;
}

.slider-text h1 {
  white-space: nowrap;
  font-size: 3rem;
  color: #fff;
}

.slider-text p {
  margin-top: 3%;
  white-space: nowrap;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #fff;
}

.btn {
  white-space: nowrap;
  font-size: 1.4rem;
  padding-block: 16px;
  width: 250px !important;
  justify-content: center;
  gap: 12px;
}

.btn-primary {
  font-size: 1.2rem !important;
  color: #fff !important;
  background-color: #ed7323;
  border: 1px solid #ed7323 !important;
  border-radius: 80px !important;
  transition: all 0.3s ease;
  font-weight: 500;
  width: fit-content;
}

.btn-secondary {
  font-size: 1.2rem !important;
  color: #fff !important;
  outline: none !important;
  border: none;
  background: linear-gradient(
    169.98deg,
    rgba(132, 136, 143, 0.2) 27.98%,
    rgba(87, 81, 81, 0.2) 81.2%
  );
  border-radius: 80px !important;
  transition: all 0.3s ease;
  font-weight: 500;
}

.btn-primary:hover {
  background-color: #ed7323;
  opacity: 0.7;
}

.btn:is(:focus, :active) {
  outline: none;
  background: none !important;
  border: 1px solid #ed7323 !important;
  box-shadow: none;
}
.btn-secondary:hover {
  opacity: 0.7;
}

.text-orange {
  color: #ed7323;
  font-weight: 700;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.186749px;
  line-height: 110%;
}

.text-white {
  color: #fff;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.186749px;
  line-height: 110%;
}

@media (max-width: 1800px) {
  .arrow {
    width: 1.5vw;
    height: 2vh;
  }

  .slider-text h1 {
    font-size: 2.3rem;
    margin-top: 5%;
  }

  .slider-text p {
    margin-top: 25%;
    font-size: 1.5rem;
  }

  .btn {
    margin-top: 10%;
    font-size: 1rem;
    padding: 0.7rem 1.1rem 0.7rem 1.1rem;
  }

  .btn-primary {
    font-size: 0.2rem;
    border-radius: 80px !important;
    padding: 3;
  }
  .btn-secondary {
    font-size: 0.2rem;
    border-radius: 80px !important;
    padding: 3;
  }
}

@media (max-width: 1200px) {
  .slider-text {
    top: 15%;
  }
  .arrow {
    width: 2vw;
    height: 1.8vh;
  }

  .slider-text h1 {
    font-size: 2rem;
    margin-top: 5%;
  }

  .slider-text p {
    margin-top: 30%;
    font-size: 1.5rem;
  }

  .btn {
    margin-top: 15%;
    font-size: 1rem;
    padding: 0.7rem 1rem 0.7rem 1.2rem;
  }

  .btn-primary {
    font-size: 0.2rem;
    border-radius: 80px !important;
    padding: 3;
  }
  .btn-secondary {
    font-size: 0.2rem;
    border-radius: 80px !important;
    padding: 3;
  }
}

@media (max-width: 768px) {
  .slider-text {
    top: 27%;
  }
  .arrow {
    width: 2.2vw;
    height: 2vh;
  }

  .slider-text h1 {
    font-size: 1.6rem;
    margin-top: 5%;
  }

  .slider-text p {
    margin-top: 30%;
    font-size: 1.1rem;
  }

  .btn {
    margin-top: 10%;
    font-size: 1.1rem !important;
    padding: 0.5rem 0.8rem;
  }

  .btn-primary {
    font-size: 0.2rem;
    border-radius: 80px !important;
    padding: 2;
  }
  .btn-secondary {
    font-size: 0.2rem;
    border-radius: 80px !important;
    padding: 2;
  }
}

@media (max-width: 600px) {
  .arrow {
    width: 2.8vw;
    height: 2.3vh;
  }

  .slider-text h1 {
    font-size: 1.3rem;
  }

  .slider-text p {
    margin-top: 35%;
    font-size: 0.9rem;
  }

  .btn {
    margin-top: 7%;
    font-size: 1rem !important;
    padding: 0.4rem 0.6rem 0.4rem 0.9rem;
  }

  .btn-primary {
    font-size: 0.2rem;
    border-radius: 80px !important;
    padding: 3;
  }
  .btn-secondary {
    font-size: 0.2rem;
    border-radius: 80px !important;
    padding: 3;
  }
}

@media (max-width: 576px) {
  .hero-img {
    height: 450px;
  }
  .slider-text {
    width: 100% !important;
  }
  .slider-text > p,
  .slider-text > h1 {
    margin: 0 !important;
    max-width: 370px;
  }
  .paragraph-container > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    text-align: center;
    letter-spacing: -0.183109px;
    padding: 0 16px;
    color: #ffffff;
  }
  .vector-icon {
    margin-left: 8px;
  }
  .slider-text > h1,
  .slider-text > .h1 {
    font-size: 24px;
    text-align: center;
  }

  .slider-text p,
  .slider-text h1 {
    white-space: normal;
  }
  .slider-text > h1 {
    margin: 0px 24px !important;
  }
  .btn {
    padding-block: 18px !important;
  }
}
</style>
