<template>
  <transition name="modal-animation">
    <div
      v-if="showModal"
      class="cow-modal"
      :style="{ zIndex: zIndex }"
      @click.self="$emit('click-outside')"
      role="dialog"
    >
      <slot> </slot>
    </div>
  </transition>
</template>

<script>
// ==================== Interfaces ====================
export default {
  name: "Modal",
  props: {
    showModal: {
      default: false,
    },
    zIndex: {
      default: 9999,
    },
  },
  // ========================= events =========================
  methods: {
    emitClickOutside() {
      this.$emit("click-outside");
    },
  },
  watch: {
    showModal(newValue) {
      if (newValue) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },
  },
};
</script>

<style scoped>
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}

.cow-modal {
  position: fixed;
  inset: 0;
  background-color: rgb(56 56 56 / 73%);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style>
body.modal-open {
  overflow: hidden !important;
}
</style>
