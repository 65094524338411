<template>
  <div>
    <div class="container">
      <div class="row career-container">
        <div class="col-12">
          <h2 class="mt-0 mb-lg-4 align-center">Open Positions</h2>
          <p class="text-center py-3">
            All positions are located at our office in F-7 Markaz, Islamabad.
          </p>
        </div>

        <div v-for="(jobs, department) in job" :key="department">
          <div class="row custom-heading career-container">
            <div class="col-lg-1"></div>
            <div class="col-sm-12 col-md-12 col-lg-10">
              <div class="card-box2">
                <h4 class="fw-bold">{{ department }}</h4>
              </div>
            </div>
            <div class="col-lg-1"></div>
          </div>
          <div
            class="row career-container"
            :key="index"
            v-for="(career, index) in jobs"
          >
            <div class="col-lg-2 col-md-2"></div>
            <div class="col-sm-12 col-md-8 col-lg-8 career-container">
              <a
                :href="`${config.jobs_url}/${career.hash}`"
                target="_blank"
                class="career-link"
              >
                <CareersCard :career="career" class="card-hover" />
              </a>
            </div>
            <div class="col-lg-2 col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CareersCard from "@/components/cards/CareersCard.vue";
import config from "../utils/config";
import { getRequest } from "../services/axios-client";
export default {
  // name: "AddTask",

  components: {
    CareersCard,
  },
  data() {
    return {
      job: {},
      config: config,
    };
  },
  async mounted() {
    const apiUrl = config.jobs_api_url;
    try {
      const response = await getRequest(apiUrl);
      const jobs = response.data.results;
      const categorized = {};
      jobs.forEach((job) => {
        const category = job.organization_name.split("-")[0].trim();
        if (!categorized[category]) {
          categorized[category] = [job];
        } else {
          categorized[category].push(job);
        }
      });

      //  custom sorting function based on order
      const customSort = (a, b) => {
        const order = ["Engineering", "BSSO", "Office Operations"];
        return order.indexOf(a) - order.indexOf(b);
      };

      const sortedCategoryKeys = Object.keys(categorized).sort((a, b) =>
        customSort(a, b),
      );

      // Create a new object with the sorted categories
      const sortedCategories = {};
      sortedCategoryKeys.forEach((key) => {
        sortedCategories[key] = categorized[key];
      });

      this.job = sortedCategories;
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  },
};
</script>

<style scoped>
.container {
  overflow: hidden;
}
.custom-heading {
  margin-top: 0px;
  padding-top: 0px;
}

.row {
  justify-content: center;
}

.card-box2 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 25px 0px 0px 0px;
}

.align-center {
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.144962px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.card-hover:hover {
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .career-container {
    padding: 0 !important;
  }
  .career-container div > h2 {
    padding: 0 !important;
    margin: 0 !important;
  }
  .card-box2 > h4 {
    font-weight: 500 !important;
    font-size: 18px !important;
  }
}

.career-link {
  text-decoration: none;
  color: inherit;
}
</style>
