<template>
  <div class="container-fluid">
    <div class="contact-container">
      <h2 class="contact-title">Contact Us!</h2>
    </div>
    <div class="container d-flex">
      <div class="row justify-content-center mx-auto">
        <div class="col-10 col-md-3 text-center contact-options">
          <div>
            <img
              class="vector"
              style="height: fit-content"
              src="/assets/Vector.png"
              alt="Image"
            />
            <h6 class="heading">Email</h6>
            <div>
              <a href="mailto:careers@cowlar.com" class="info-text"
                >careers@cowlar.com</a
              >
            </div>
          </div>
        </div>
        <div class="col-1 text-center horizontal-line"></div>
        <div class="col-10 col-md-3 text-center contact-options">
          <span>
            <img
              class="vector vector-2"
              src="/assets/Vector1.png"
              alt="Image"
            />
          </span>

          <h6 class="vertical-line"></h6>
          <h6 class="heading2">Pakistan</h6>

          <div class="d-flex">
            <a
              href="https://www.google.com/maps/place/Cowlar+Design+Studio/@33.7192344,73.0514502,17z/data=!3m2!4b1!5s0x38dfbfa6491301bd:0x192733485df0677e!4m6!3m5!1s0x38dfbf69f1c13bc5:0x8078f2bb7bd09520!8m2!3d33.71923!4d73.0540251!16s%2Fg%2F11p16p4x22?shorturl=1"
              target="_blank"
              ><p class="info-text-small">
                Plot # 5-A, EOBI Building, 1st & 2nd Floor, F-7
                Markaz,Islamabad, Pakistan
              </p>
            </a>
          </div>
        </div>
        <div class="col-1 col-sm-1 text-center horizontal-line"></div>
        <div class="col-10 col-sm-10 col-md-3 text-center contact-options">
          <img class="vector vector-2" src="/assets/Vector1.png" alt="Image" />

          <h6 class="vertical-line"></h6>
          <h6 class="heading2">USA</h6>

          <div class="d-flex">
            <a
              href="https://www.google.com/maps/place/88+Union+Ave+Suite+200,+Memphis,+TN+38103,+USA/@35.143551,-90.0565286,17z/data=!3m1!4b1!4m6!3m5!1s0x87d57e95d5d6aaab:0x87f159273cc2ae5a!8m2!3d35.1435466!4d-90.0539537!16s%2Fg%2F11mhjxfkv0?shorturl=1"
              target="_blank"
              ><p class="info-text-small">
                88 Union Ave, Suite 200 Memphis, TN, United States, 38103
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "RecentWork",
  components: {},
  setup() {
    const router = useRouter();
    return { router };
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
  text-decoration: none;
}
.container {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  background: #2d2d2e;
  border-radius: 10px;
}
h6 {
  color: #b5b8ba;
}
.heading {
  font-size: large;
  margin-bottom: 0;
  opacity: 1 !important;

  margin-top: 5%;
}

.info-text {
  font-size: medium;
  opacity: 0.6;
  margin: 0;
  color: #b5b8ba;
}

.info-text-small {
  font-size: medium;
  opacity: 0.6;
  margin-top: -18%;
  margin-left: 15%;

  color: #b5b8ba;
}

.vector {
  margin-top: 15%;
  height: 30px;
  width: 25px;
}

.vector-2 {
  margin-left: 8%;
  height: 30px;
  width: 25px;
}

.vector:hover,
.vector-2:hover {
  /* Add your desired hover effect styles here */
  cursor: pointer;
  opacity: 0.5;
}

@media (min-width: 992px) {
  .vertical-line {
    border-left: 1px solid white;
    height: 5vh;
    margin-left: -3vw;
    margin-right: -3vw;
    margin-top: -2vh;
    opacity: 0.6;
  }

  .info-text-small {
    margin-top: -0.55vh;
    margin-left: 0;
  }

  .heading2 {
    font-size: large;
    margin-top: -3vh !important;
    opacity: 1 !important;
    margin-left: 0px;
  }

  .vector-2 {
    margin-left: 0px;
    height: 30px;
    width: 25px;
  }
}

@media (max-width: 991px) {
  .horizontal-line {
    opacity: 0 !important;
  }
  .container {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    margin-top: 5%;
    height: 600px;
  }

  .vector {
    justify-content: center;
    align-items: center;
  }

  .vector-2 {
    margin-left: 0%;
    margin-top: 0%;
  }

  .heading {
    margin-bottom: 0;
    margin-top: 3%;
  }

  .heading2 {
    font-size: large;
    margin-bottom: 0;
    margin-top: 0%;
  }

  .info-text {
    font-size: medium;
    opacity: 0.6;
    margin: 0;
  }

  .info-text-small {
    margin: 0;
    margin-top: 0%;
    margin-bottom: 1%;
  }

  .horizontal-line {
    height: 2px;
    margin: 2vh;
    opacity: 0.3;
    background-color: white;
    width: 100%;
  }
}

.contact-title {
  text-align: center;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .container {
    width: 98%;
    height: 440px !important;
  }
  .horizontal-line {
    display: none;
  }
  .contact-title {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.152132px;
  }
  .vector {
    margin-top: 10% !important;
  }
  .vector-2 {
    margin: 0 !important;
  }
  .heading,
  .heading2,
  .heading3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #b5b8ba;
    margin-top: 16px !important;
    margin-bottom: 8px !important;
  }

  .contact-options {
    padding-bottom: 0 !important;
    margin: 0 !important;
    position: relative;
  }
  .info-text,
  .info-text-small {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 120%;
    color: #b5b8ba;
  }
  .contact-options:last-child:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 0.7px;
    background-color: #ffffff;
    left: 0;
    bottom: 15%;
    opacity: 0.6;
  }
}
</style>
