export const projectInfo = [
  {
    title: "Embedded Team",
    description:
      "Our embedded team integrates cutting-edge electronics and software, crafting robust solutions that streamline and elevate your technological needs. With expertise in ML/AI, robotics, and IoT, we fuse innovation with practicality, turning complex challenges into seamless systems. Together, we're shaping the future of embedded technology, ensuring reliability and efficiency at the core of your operations.",
    picture: "../assets/EmbeddedTeam.png",
  },
  {
    title: "Electronics Team",
    description:
      "Our Electronics Team, is specialized in proficient design, detailed manufacturing, and comprehensive testing. Leveraging the latest technology, we provide sustainable, efficient, and strong electronic solutions catering to our clients needs. Driven by the pursuit of excellence, we are committed to turning ideas into reliable and innovative electronic products.",
    picture: "../assets/ElectronicsTeam.png",
  },

  {
    title: "Supply Chain & Sourcing Team",
    description:
      "Our Supply Chain & Sourcing team specializes in global procurement for engineering sectors. We provide cost-efficient sourcing solutions with a focus on product quality and delivery speed, optimizing your technology projects with reliable resources and logistics.",
    picture: "../assets/SupplyChainTeam.png",
  },

  {
    title: "PRIME Team",
    description:
      "Our PRIME team – specialists in Product, Robotics, Industrial, and Mechanical Engineering – stands as a testament to our dedication to innovation. With expertise spanning Mechatronics, Mechanical, Industrial, and Material engineering, we are continually redefining the boundaries of design and technology.",
    picture: "../assets/PRIMETeam.png",
  },
  {
    title: "Enterprise Software Team",
    description:
      "Our Enterprise Software Team is at the heart of our tech-driven operations, meticulously developing advanced solutions in line with industry-leading standards. Their dedication to continual innovation fuels our digital evolution, IoT infrastructures, and custom software offerings, constantly challenging and extending the limits of digital capabilities.",
    picture: "../assets/SoftwareEnterpriseTeam.png",
  },

  {
    title: "Mobile App Development Team",
    description:
      "Our Mobile App Development Team is a force of innovation, crafting cutting-edge mobile solutions. With expertise in iOS and Android platforms, we create seamless user experiences and deliver robust, high-performance mobile applications. Trust us to transform your ideas into captivating mobile experiences that drive business growth.",
    picture: "../assets/MobileAppDevelopmentTeam.png",
  },

  {
    title: "Quality Assurance Team",
    description:
      "Our Quality Assurance Team meticulously ensures the highest standard of our products, combining manual and automated testing for both hardware and software. Our dedicated experts scrutinize each detail, safeguarding functionality, reliability, and user experience. With their unwavering commitment, they guarantee that our products consistently meet and exceed your expectations.",
    picture: "../assets/QATeam.png",
  },

  {
    title: "SAMA Team",
    description:
      "With their expertise in AI-driven technologies, our Software, Algorithms, Machine Learning, and AI (SAMA) Team develops intelligent solutions, leveraging advanced data analytics and predictive modeling.  Their expertise enables businesses to harness the power of AI-driven technologies, driving industry transformation and unlocking new possibilities.",
    picture: "../assets/SAMATeam.png",
  },

  {
    title: "Product Design Team",
    description:
      "Our Product Design Team is a hub of innovation and creativity, expertly balancing functionality and visual appeal in every design. Mastering UI/UX principles and keeping pace with the latest design trends, they turn user interactions into smooth, engaging, and visually stunning experiences. Their seasoned expertise breathes life into ideas, crafting intuitive interfaces that facilitate impactful interactions between users and our technology.",
    picture: "../assets/ProductDesignTeam.png",
  },

  {
    title: "Business Strategy & Operations Team",
    description:
      "Our Business Strategy & Operations Team are the architects of our success, weaving together strategic planning and operational excellence to support our vision. They are strategic thinkers and process optimizes, ensuring our engineering solutions are efficiently developed, effectively managed, and successfully brought to market.",
    picture: "../assets/BusinessOps.png",
  },

  {
    title: "Technical Operations Team",
    description:
      "Our Technical Operations Team drives the seamless execution of projects with a deep understanding of design, software, hardware, and quality assurance, they ensure seamless execution of projects. Their collaborative approach and attention to detail guarantee timely delivery and exceptional results.",
    picture: "../assets/TechnicalOperations.png",
  },

  {
    title: "Talent Acquisition & HR Team",
    description:
      "Our Talent Acquisition & HR Team specializes in finding skilled professionals who will drive innovation and success in the organization. Through a seamless recruitment process, we conduct thorough technical evaluations and cultural fit assessments with in-depth industry knowledge and extensive network to ensure that best candidates are presented.",
    picture: "../assets/Talent&HRTeam.png",
  },

  {
    title: "Data Annotation Team",
    description:
      "Our data annotation team consists of detail-oriented experts skilled in converting raw data into machine-understandable formats. We meticulously label images, text, and audio, adding vital context that refines machine learning models. Harnessing our skills, we enable AI systems to gain valuable insights from the data, driving better decision-making and predictions.",
    picture: "../assets/DataAnnotationTeam.png",
  },

  {
    title: "Office Operations Team",
    description:
      "Our Office Operations Team forms the vital link that keeps our organization running smoothly, ensuring the efficiency and harmony of our day-to-day operations. We support every facet of the business, from managing administrative tasks to facilitating the engineering teams, enabling our them to focus on what they do best - creating superior engineering solutions!",
    picture: "../assets/OfficeOpsTeam.png",
  },

  {
    title: "Support Team",
    description:
      "Our Support Team is deeply committed to delivering comprehensive operational, administrative, and technical services across the organization. Leveraging our expertise and robust tools, we resolve technical issues swiftly and ensure optimal performance. In pursuit of fostering a smooth, efficient, and highly productive work environment, we continuously evolve our strategies and methodologies to better serve your needs.",
    picture: "../assets/SupportTeam.png",
  },
];

export const projectDetail = {
  kiosk: [
    {
      images: [
        "/assets/products/Kiosk-images/kiosk1.webp",
        "/assets/products/Kiosk-images/kiosk2.webp",
        "/assets/products/Kiosk-images/kiosk3.webp",
        "/assets/products/Kiosk-images/kiosk4.webp",
        "/assets/products/Kiosk-images/kiosk5.webp",
      ],
      title: "Self-Service Kiosk",
      description: ` We combine core ideas of User Centered Design with software
          functionality and impeccable hardware to create a self-service
          Kiosk. The mission was to deeply connect with the consumer and to
          understand what works best. Cowlar’s in-house designers and
          engineers worked day and night to make this miracle of technology
          possible.
          <br />
          <br />
          A self-service table kiosk provides contactless customer
          interaction centers. It can be easily placed in Hotels, Banks,
          Airports and Telecom franchises - whether you want to get a sim,
          room key card or more. its agent free, quick and a convenient
          experience.Self Service Kiosk is a step into the digital era
          connecting deeply with the customer and leading your organization
          into digital growth utilizing the power of Data and AI while
          building a unique customer experience, Optimized Operations at
          reduced operational cost.`,
      cards: [
        {
          img: "/assets/images/Vector.png",
          title: "Modular Design",
          description:
            "The Design have fewer components that helps in easy assembly and effortless maintenance.",
        },
        {
          img: "/assets/images/Vector.png",
          title: "Customization",
          description:
            "Customizing capacity to elicit and translate requirements to build products in line with the expectations",
        },
        {
          img: "/assets/images/Vector.png",
          title: "Seamless Integration",
          description:
            "Easy integration with third party apps and back-end systems.",
        },
        {
          img: "/assets/images/Vector.png",
          title: "24/7 Operations",
          description:
            "The Kiosk is able to operate 24/7 with little to no human intervention",
        },
      ],
      lists: [
        {
          title: "Types of Kiosks",
          list: [
            "Sim Dispensing Kiosks",
            "Cash & Check Deposit Kiosks",
            "Card Issuance Kiosks",
            "QSR Kiosks (Quick Service Restaurant kiosks)",
            "Self Checkout Kiosks",
          ],
        },
        {
          title: "Technical Specifications",
          list: [
            `Designed according to client requirements. Modular Design that can
            easily be repurposed, while providing users with 24/7 service.
            Through seamless integration add third party applications and
            back-end systems`,
          ],
        },
      ],
    },
  ],

  automa: [
    {
      images: [
        "/assets/products/Automa-images/Automa1.webp",
        "/assets/products/Automa-images/Automa2.webp",
        "/assets/products/Automa-images/Automa3.webp",
        "/assets/products/Automa-images/Automa4.webp",
        "/assets/products/Automa-images/Automa5.webp",
        "/assets/products/Automa-images/Automa6.webp",
      ],
      title: "Automa",
      description:
        "Automa is the ultimate device to control your HVAC systems, the smart way. With Automa you will be able to control, monitor, automate and achieve impressive energy savings ( up to 30%). It is ideal for all institutions where energy systems account to a significant portion of the of cost e.g. Banks, Hotels etc.",
      cards: [
        {
          img: "/assets/images/Vector.png",
          title: "Connectivity",
          description:
            "Automa can be used with any Air Conditioner regardless of the appliance’s Wifi capabilities.",
        },
        {
          img: "/assets/images/Vector.png",
          title: "Energy Saving",
          description:
            "Automa provides users with their live energy consumption data along with automated alerts.",
        },
        {
          img: "/assets/images/Vector.png",
          title: "Climate Change",
          description:
            "Control the AC temprature settings from anywhere in the world.",
        },
        {
          img: "/assets/images/Vector.png",
          title: "Scheduling",
          description:
            "The ability to schedule any function available in the AC suite in advance",
        },
      ],
      lists: [
        {
          title: "Use Cases",
          list: [
            "Get notifications and alerts on your power consumption at home.",
            "Hotels can use the Automa system to achieve guaranteed savings on their energy expenses",
            "Reduce operational costs for large organizations such as banks",
            "Control the entire premises simultaneously from a single device",
          ],
        },
        {
          title: "Technical Specifications",
          list: [
            "Size: 34 mm x 31 mm",
            "Retrofit: Automa is compatible with all HVAC systems/devices",
            "Battery: Up to 1 year of backup uninterrupted battery",
            "Convenience: Real Time insights right in your Smartphone/PC",
            "Geo-Fencing: Ability to integrate a virtual perimeter to control operational capability of HVAC",
          ],
        },
      ],
    },
  ],
};
