<template>
  <section class="">
    <h2 class="title">Cookies Settings</h2>
    <p class="update-time">Last Update: 31st May 2023</p>
    <p class="tagline">
      This Cookie Settings document explains how Cowlar Design Studio ("we,"
      "us," or "our") uses cookies and similar technologies on the
      careers.cowlardesignstudio.com website (the "Site") where individuals
      share their CVs and personal data. By using the Site, you consent to the
      use of cookies and similar technologies as described below.
    </p>
    <div>
      <h3 class="section-heading">1. What are Cookies?</h3>
      <p class="section-description">
        Cookies are small text files that are placed on your device when you
        visit a website. They are commonly used to make websites work more
        efficiently and provide a better browsing experience. Cookies enable the
        website to remember your actions and preferences (such as login
        information, language selection, and font size) over a period of time,
        so you don't have to re-enter them whenever you revisit the site or
        browse from one page to another.
      </p>

      <h3 class="section-heading">2. Types of Cookies We Use</h3>
      <div class="section-description">
        <ol>
          <li>
            Essential Cookies: These cookies are necessary for the operation of
            the Site and enable you to access and use its features. They are
            usually set in response to your actions, such as filling out forms
            or setting preferences. Without these cookies, certain services on
            the Site may not be available to you.
          </li>
          <li>
            Analytics Cookies: These cookies help us understand how visitors
            interact with the Site by collecting and reporting information
            anonymously. We use this data to analyze trends, administer the
            site, and improve the user experience. The information collected may
            include your IP address, browser type, device information, and
            browsing behavior on the Site.
          </li>
          <li>
            Functional Cookies: These cookies allow the Site to remember choices
            you make and provide enhanced functionality and personalization. For
            example, they may remember your username, language preference, or
            region selection. These cookies may also be used to provide services
            you have requested, such as submitting your CV or application.
          </li>
          <li>
            IAdvertising Cookies: We do not use advertising cookies on the Site.
          </li>
        </ol>
      </div>

      <h3 class="section-heading">3. Managing Cookie Preferences</h3>
      <div class="section-description">
        You can control and manage cookies through your browser settings. Most
        web browsers allow you to block or delete cookies and provide options to
        notify you when a cookie is being sent. Please note that blocking or
        deleting cookies may impact your experience on the Site and limit
        certain features and functionality. To manage your cookie preferences,
        please refer to the instructions provided by your browser:
        <ul>
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647"
              target="_blank"
              style="color: #b5b8ba"
              >Google Chrome</a
            >
          </li>
          <li>
            <a
              href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
              target="_blank"
              style="color: #b5b8ba"
              >Mozilla Firefox</a
            >
          </li>
          <li>
            <a
              href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              target="_blank"
              style="color: #b5b8ba"
            >
              Safari</a
            >
          </li>
          <li>
            <a
              href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
              target="_blank"
              style="color: #b5b8ba"
              >Microsoft Edge</a
            >
          </li>
        </ul>
        <div>
          Please note that if you choose to disable or block certain cookies,
          some features of the Site may not function properly, and your ability
          to use certain services or access certain content may be affected.
        </div>
      </div>

      <h3 class="section-heading">4. Updates to this Cookie Settings</h3>
      <p class="section-description">
        We may update this Cookie Settings from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        Any changes will be effective immediately upon posting the updated
        version on the Site. We encourage you to review this document
        periodically to stay informed about our use of cookies.
      </p>

      <h3 class="section-heading">5. Third-Party Cookies</h3>
      <p class="section-description">
        In addition to the cookies mentioned above, the Site may also use
        third-party cookies provided by trusted third-party service providers.
        These cookies may collect information about your browsing behavior on
        the Site and other websites you visit, allowing us to deliver
        personalized advertisements or content based on your interests.Please
        note that we have no control over third-party cookies, and their use is
        subject to the respective third-party providers' privacy policies. We
        recommend reviewing the privacy policies of these providers to
        understand how they collect, use, and disclose your information.
      </p>

      <h3 class="section-heading">6. Data Retention</h3>
      <p class="section-description">
        The Website may contain links to third-party websites that are not
        operated or controlled by Cowlar Design Studio. This Privacy Policy
        applies only to our Website, and we are not responsible for the privacy
        practices or content of any third-party websites. We encourage you to
        review the privacy policies of those websites before providing any
        personal information.
      </p>

      <h3 class="section-heading">7. Consent to the Use of Cookies</h3>
      <p class="section-description">
        By continuing to use the Site and accepting our Cookie Settings, you
        consent to the use of cookies as described herein. You have the right to
        withdraw your consent at any time by adjusting your cookie settings
        through your browser or by contacting us.
      </p>

      <h3 class="section-heading">8. Changes to Cookie Settings</h3>
      <p class="section-description">
        We reserve the right to make changes to our Cookie Settings at any time
        without prior notice. Any updates or modifications to our use of cookies
        will be reflected in the revised version of this document, which will be
        posted on the Site. We recommend reviewing this Cookie Settings
        periodically to stay informed about any changes.
      </p>

      <h3 class="section-heading">9. Contact Information</h3>
      <p class="section-description">
        If you have any questions, concerns, or requests regarding our use of
        cookies or this Cookie Settings, please contact us at info@cowlar.com .
        We will be happy to assist you. By using the Site and continuing to
        share your CV and personal data, you acknowledge that you have read,
        understood, and agree to the use of cookies as described in this Cookie
        Settings.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "CookiesSettings",
};
</script>

<style scoped>
.container {
  margin: auto !important;
}

.title {
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.update-time {
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.tagline {
  font-weight: 600;
  font-size: 32px;
  line-height: 107.5%;
  letter-spacing: -0.148861px;
  color: #f07b22;
}

.section-heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 107.5%;
  letter-spacing: -0.148861px;
  color: #ffffff;
  margin: 48px 0px 24px 0px;
}

.section-description,
li {
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.148861px;
  color: #b5b8ba;
}

.section-description > a {
  color: #ed5023;
  text-decoration: none;
}

li {
  padding-bottom: 16px;
}

@media (max-width: 768px) {
  .container {
    padding: 8px 20px;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 107.5%;
    /* identical to box height, or 26px */

    letter-spacing: -0.148861px;
    text-transform: uppercase;

    color: #ffffff;
  }

  .update-time {
    font-weight: 300;
    font-size: 14px;
    line-height: 107.5%;
    /* identical to box height, or 15px */

    letter-spacing: -0.148861px;

    color: #ffffff;
  }

  .tagline {
    font-weight: 600;
    font-size: 20px;
    line-height: 107.5%;
    /* or 22px */

    letter-spacing: -0.148861px;

    color: #f07b22;
  }

  .section-heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 107.5%;
    /* or 19px */

    letter-spacing: -0.148861px;

    color: #ffffff;
  }

  .section-description,
  li {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: -0.148861px;

    color: #b5b8ba;
  }
}
</style>
