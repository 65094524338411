<template>
  <div class="">
    <div class="row g-0" id="cardsContainer">
      <div
        id="cardImage"
        class="col-md-4"
        style="border-radius: 6px; border: solid; overflow: hidden"
      >
        <img src="../../assets/dummy.jpg" class="img-fluid rounded" />
      </div>
      <div
        id="cardBody"
        class="col-md-6 bg-secondary"
        style="border-radius: 6px; overflow: hidden"
      >
        <!-- Card body -->
        <div class="card-body align-self-center">
          <h5 class="card-title d-flex justify-content-center">
            {{ member.title }}
          </h5>
          <h6 class="card-title d-flex justify-content-center">
            {{ member.position }}
          </h6>
          <p class="card-text d-flex justify-content-center">
            {{ member.description }}
          </p>

          <div class="card-text d-flex justify-content-center">
            <a href="" class="text-white me-4">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="" class="text-white me-4">
              <i class="fab fa-twitter"></i>
            </a>

            <a href="" class="text-white me-4">
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // name: "AddTask",
  components: {
    // Header,
  },
  props: {
    member: Object,
  },
};
</script>

<style scoped>
.card-body {
  flex-direction: column;
  align-items: center;
}
#cardImage {
  width: max-content;
  margin-bottom: 10px;
  margin-right: 10px;
}
#cardBody {
  margin-bottom: 10px;
  margin-right: 10px;
}
.cardsContainer {
  align-items: center;
}
#cardsContainer {
  justify-content: center;
}
</style>
