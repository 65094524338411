<template>
  <div class="container pb-4">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <h2 class="align-center justify-content-center talent-top">
          We can’t wait to meet the Top Talent!
        </h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="col-12 col-md-10 col-lg-10 row justify-content-center text-center talent-desc-container"
      >
        <div class="col-10 col-md-7 row3 talent-desc">
          We would love to hear more from you and learn more about your
          experience and aspirations. Let’s grow together!
        </div>
      </div>
    </div>
    <div class="row justify-content-center button-container">
      <div
        class="col-md-auto col-6 d-flex align-items-center justify-content-center btn-container"
      >
        <a
          @click="scrollToSectionFullTime('#careers')"
          class="button btn btn-primary btn-lg btn-apply-now btn1-apply"
          >Apply - Full Time</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "RecentWork",
  components: {},
  methods: {
    scrollToSectionFullTime(sectionId) {
      const section = document.querySelector(sectionId);
      if (section) {
        const offset = section.offsetTop - 50;
        window.scrollTo({ top: offset, behavior: "smooth" });
      }
    },
    scrollToSectionInternship(sectionId) {
      const section = document.querySelector(sectionId);
      if (section) {
        const offset = section.offsetTop + 2700;
        window.scrollTo({ top: offset, behavior: "smooth" });
      }
    },
  },
  setup() {
    const router = useRouter();

    return {
      router,
    };
  },
};
</script>

<style scoped>
.container {
  background: linear-gradient(
    91.49deg,
    rgba(244, 119, 38, 0.175) 0%,
    rgba(240, 123, 34, 0.25) 100.16%
  );
  border-radius: 20px;
}

.talent-top {
  margin-top: 25px;
  font-size: 36px;
  font-weight: 700 !important;
  text-align: center;
  font-style: normal;
}

.row2 {
  justify-content: center;
  text-align: center;
  margin-top: 0.5vh;
}

.row3 {
  opacity: 1;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.btn {
  white-space: nowrap;
  font-size: 1.4rem;
  padding: 1rem 2rem;
}

.btn-primary {
  white-space: nowrap;
  margin-top: 2vh;
  font-size: larger;
  font-weight: 600;
  color: #fff !important;
  border-radius: 80px !important;
  transition: all 0.3s ease;
}

.btn2-intern {
  background-color: rgba(244, 120, 38, 0.1);
  border-color: #f28636;
  margin-left: 31%;
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  border: 2px solid rgba(244, 123, 42, 1);
  border-radius: 80px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.btn1-apply {
  background-color: #f28636;
  border: none;
}

.btn1-apply:hover {
  background-color: #f28636;
  opacity: 0.7;
}

.btn-primary {
  background-color: #f28636;
}

.btn2-intern {
  background-color: rgba(244, 120, 38, 0.1);
  border-color: #f28636;
  margin-left: 10px;
}

.btn2-intern:hover {
  background-color: rgba(244, 120, 38, 0.1);
  border-color: #f28636;
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .button-container {
    margin: 4px 8px !important;
  }
  .btn2-intern {
    margin-left: 0 !important;
  }

  .btn-apply-now {
    padding: 5px 13px !important;
    max-width: 160px !important;
    height: 30px !important;
    font-size: 14px;
    line-height: 110%;
    letter-spacing: -0.186749px;
  }
  .btn1-apply {
    padding: 7px 13px !important;
  }

  .talent-desc-container {
    padding: 0 !important;
    margin: 0 !important;
  }

  .talent-desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    /* or 22px */
    width: 100% !important;
    max-width: 100% !important;
    text-align: center;
  }

  .btn-container {
    margin: 0 !important;
  }

  btn-container:first-child {
    margin-right: 16px;
  }
}

@media screen and (max-width: 400px) {
  .btn-apply-now {
    padding: 5px 14px !important;
    max-width: 140px !important;
    height: 30px !important;
    font-size: 12px;
    line-height: 110%;
    letter-spacing: -0.186749px;
  }
  .btn1-apply {
    padding: 7px 16px !important;
  }
}

@media screen and (max-width: 980px) {
  .talent-top {
    margin-top: 16px;
    font-size: 24px;
    font-weight: 700 !important;
    text-align: center;
    font-style: normal;

    line-height: 120%;
    padding: 0 12px;
  }

  .row3 {
    opacity: 1;
    font-size: 18px;
  }
}
</style>
