<template>
  <div class="container pb-5">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <h2 class="align-center justify-content-center">Internship Program</h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="col-12 col-md-10 col-lg-10 row justify-content-center text-center"
      >
        <div class="col-12 row3">
          Cowlar Design Studio offers an engaging internship program tailored
          specifically for 6th semester students. This opportunity provides
          hands-on experience in design, facilitating skill enhancement, and
          offering a glimpse into the professional world of design.
        </div>
      </div>
    </div>
    <div class="row2 justify-content-center">
      <div class="apply-button-container">
        <a
          href="#careers"
          class="button btn btn-primary btn-lg btn-apply-now mt-4.5"
          >Apply Now</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "RecentWork",
  components: {},
  setup() {
    const router = useRouter();

    return {
      router,
    };
  },
};
</script>

<style scoped>
.heading {
  margin: 3vh;
  margin-left: 5vw;
}

.row2 {
  justify-content: center;
  text-align: center;
  margin-top: 0.5vh;
}
.row {
  margin-top: 0.7vh;
}
.row3 {
  opacity: 0.7;
}

.apply-button-container > button {
  margin-top: 3vh;
  border-radius: 30px;
  background: linear-gradient(
    169.98deg,
    rgb(132 136 143 / 20%) 27.98%,
    rgb(87 81 81 / 20%) 81.2%
  );
  box-shadow: 0 111.867px 223.734px rgb(255 255 255 / 20%);
  color: white;
  font-size: 0.9vw;
  padding: 10px 20px 10px 20px;
  font-weight: 400;
  border: none;
  margin-top: 5vh !important;
}

.apply-button-container > button:hover {
  /* background: #f28636;
  color: #fff; */
  opacity: 0.6;
}

.btn {
  white-space: nowrap;
  font-size: 1.4rem;
  padding: 1rem 2rem;
}
.btn-primary {
  white-space: nowrap;
  margin-top: 3vh;
  font-size: larger;
  position: absolute;
  transform: translate(-50%, -50%);
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  letter-spacing: -0.298312px;
  color: #fff !important;
  background-color: transparent !important;
  border: none !important;
  background: linear-gradient(
    169.98deg,
    rgba(132, 136, 143, 0.2) 27.98%,
    rgba(87, 81, 81, 0.2) 81.2%
  );
  border-radius: 80px !important;
  padding: 1vh 2vh;
  border-radius: 30px;
  transition: all 0.3s ease;
}
.btn-primary:hover {
  /* background-color: #b9b7b5 !important;
  background: orange; */
  opacity: 0.6;
}

@media (max-width: 991px) {
  .apply-button-container {
    white-space: nowrap;
  }
  .apply-button-container > button {
    font-size: 2vw;
  }
  .btn {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
}
</style>
