import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

//SweatAlert swal
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

// email validate
// import 'email-spam-checker';

createApp(App).use(store).use(router).use(VueSweetalert2).mount("#app");
