<template>
  <div class="container padding-0">
    <div class="justify-content-center">
      <div class="col-12 text-center">
        <h2 class="align-center justify-content-center teams-title">
          Our Teams
        </h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-3 col-md-2 col-lg-2"></div>
      <div
        class="col-11 col-md-8 col-lg-8 row2 justify-content-center align teams-desc"
      >
        At Cowlar Design Studio, our multi-disciplinary engineering teams unite,
        bringing diverse expertise to create innovative solutions. Together, we
        transform ideas into reality, pushing the boundaries of design and
        technology.
      </div>
      <div class="col-3 col-md-2 col-lg-2"></div>
      <Carousel class="caurosel-portfolio">
        <Slide
          v-for="slide in info"
          :key="slide"
          class="slide-container-via-container"
        >
          <div class="carousel__item">
            <div class="project-header">
              <p class="portfolio-product-title">{{ slide.title }}</p>
              <!-- <div class=""> -->
              <p class="portfolio-product-description">
                {{ slide.description }}
              </p>
            </div>
            <div class="bg-tran">
              <div class="portfolio-image-container bg-tran">
                <img :src="slide.picture" alt="product image" />
              </div>
            </div>
          </div>
        </Slide>
        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
  </div>
</template>
<script>
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import { useRouter } from "vue-router";
import "vue3-carousel/dist/carousel.css";
import { projectInfo } from "../data/slider";

export default {
  name: "RecentWork",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  setup() {
    const router = useRouter();
    const info = projectInfo;
    return { info, router };
  },
};
</script>

<style scoped>
/* ::v-deep selector is used to target the elements within the library-component and apply custom styles.
 It allows you to override the scoped styles of the library component specifically within your custom component.  */
::v-deep(.row) {
  margin-top: 0.7vh;
}

::v-deep(.row2) {
  margin-top: 0.7vh;
  text-align: center;
  justify-content: center;
  font-size: large;
  font-style: normal;
  font-weight: 400;
  color: #b5b8ba;
}

::v-deep(.carousel__pagination) {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center !important;
  background-color: transparent;
  align-items: center;
  margin-top: 0vh;
}

::v-deep(.carousel__pagination-button::after) {
  display: none;
}

::v-deep(.carousel__pagination-button) {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: lightgrey;
  border: none;
  box-shadow: none !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
}

::v-deep(.carousel__pagination-button--active) {
  background-color: #f28636;
  border: none !important;
  /* Hide the border */
}

::v-deep(.carousel__prev),
::v-deep(.carousel__next) {
  background-color: #f28636;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
}

::v-deep(.carousel__prev:before),
::v-deep(.carousel__next:before) {
  content: none;
  /* Remove the content (arrow) */
}

::v-deep(.carousel__prev:hover),
::v-deep(.carousel__next:hover) {
  background-color: #f28636;
  /* Apply the orange color on hover */
}
</style>

<style scoped>
.container {
  text-align: center;
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.project-header {
  margin-bottom: 10px;
  justify-content: center;
  text-align: center;
}

.project-header p {
  text-align: center;
}

.carousel__slide {
  padding: 20px;
}

.portfolio-product-title {
  /* color: black; */
  font-weight: 600;
  font-size: 22px;
}

.portfolio-product-description {
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  color: #b5b8ba;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.caurosel-portfolio {
  box-sizing: initial;
  padding: 0;
  margin-top: -3%;
}

.portfolio-image-container > img {
  width: 80vw;
  object-fit: contain;
  border-radius: 15px;
}

@media only screen and (min-width: 1000px) {
  .project-header {
    text-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  .carousel__item {
    flex-direction: row;
    padding: 50px;
  }

  .portfolio-image-container > img {
    width: 100vw;
    height: 50vh;
  }
}

@media only screen and (max-width: 1400px) {
  .portfolio-image-container > img {
    width: 35vw;
    height: 45vh;
  }
}

@media (max-width: 768px) {
  .padding-0 {
    padding: 0 !important;
  }

  .carousel__item {
    padding: 0 !important;
  }
  ::v-deep(.carousel__prev),
  ::v-deep(.carousel__next) {
    width: 20px;
    height: 20px;
    font-size: 14px;
    margin: 2%;
  }

  .portfolio-product-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    margin-top: 24px !important;
    padding: 0 !important;
    /* or 26px */

    text-align: center;
    letter-spacing: -0.152132px;
  }

  .portfolio-product-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    /* or 17px */

    text-align: center;
    letter-spacing: 2px;

    color: #b5b8ba;
  }

  .teams-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */

    text-align: center;
    letter-spacing: -0.152132px;
  }

  .teams-desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */

    text-align: center;
    letter-spacing: 2px;
    padding: 0 !important;
  }
}

@media only screen and (max-width: 1000px) {
  .portfolio-image-container > img {
    width: 70vw;
    height: 40vh;
  }
}

@media only screen and (min-width: 1401px) {
  .portfolio-image-container > img {
    width: 30vw;
    height: 35vh;
  }
}

@media only screen and (min-width: 2100px) {
  .portfolio-image-container > img {
    width: 25vw;
    height: 35vh;
  }
}

.bg-tran {
  background-color: transparent !important;
}
</style>
